<template>
  <section>
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 lg4 body-1 grey--text text--darken-1>Peso: <strong>{{peso}} kg</strong></v-flex>
        <v-flex xs12 lg4 body-1 grey--text text--darken-1>Numero colli: <strong>{{colli}}</strong></v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex xs12 lg12>
          <v-alert
            outline
            color="info"
            value="true"
            v-if="!merci.length && !bancaliDdt.length">
            Nessuna merce/bancale trovato
          </v-alert>
          <!-- lista bancali -->
          <v-list class="mt-2" two-line v-if="bancaliDdt.length">
            <template v-for="(bancale, index) in bancaliDdt">
              <v-list-tile
                avatar
                :key="'tile-'+bancale.codice_finito+index">
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{bancale.codice_finito + " - " + bancale.opera}}
                    <span v-if="bancale.numero">- N. {{bancale.numero}}</span>
                    <span v-if="bancale.descrizione"> - {{bancale.descrizione}}</span>
                    {{bancale.note}}
                  </v-list-tile-title>
                  <v-list-tile-sub-title>Bancali: {{bancale.bancali.length}}
                    <span v-if="bancale.tipo_imballo === 1">({{getInscatolatura(bancale)}})</span>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>Qtà {{bancale.qta}}</v-list-tile-action-text>
                  <v-icon @click="eliminaBancali(bancale)" color="error">delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="'div-'+bancale.codice_finito+index" v-if="index < bancaliDdt.length - 1"></v-divider>
            </template>
          </v-list>
          <v-divider :key="'divisore'" v-if="bancaliDdt.length > 0 && merci.length > 0"></v-divider>
          <!-- lista merci libere -->
          <v-list class="mt-2" two-line v-if="merci.length">
            <template v-for="(merce, index) in merci">
              <v-list-tile
                @click="openMerceLibera(merce)"
                avatar
                :key="'tile-'+merce.merce_id">
                <v-list-tile-content>
                  <v-list-tile-title><span class="text-uppercase">{{ merce.codice }}</span> - {{ merce.descrizione }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{merce.unita_misura}}: {{merce.qta}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>Qtà {{merce.qta}}</v-list-tile-action-text>
                  <v-icon @click.stop @click="eliminaMerce(merce)" color="error">delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="'div-'+merce.merce_id" v-if="index < merci.length - 1" inset></v-divider>
            </template>
          </v-list>
          <v-speed-dial
            class="fix-speed-dial"
            v-model="fab"
            bottom
            right
            fixed
            direction="top"
            transition="slide-y-reverse-transition">
            <v-btn
              color="pink darken-1"
              dark fab right bottom
              slot="activator"
              v-model="fab">
              <v-icon>add</v-icon>
              <v-icon>close</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="green"
              @click="openMerceLibera()">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="indigo"
              v-if="ddt.destinazione_id"
              @click="openBancali()">
              <v-icon>build</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex>
          <v-btn @click="stampa()">
            Stampa
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- merci libere -->
    <v-dialog v-model="showMerce" persistent max-width="500" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>
            Merce libera
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.stop="showMerce = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-card>
            <v-form ref="formMerceLibera" v-model="validFormMerce">
              <v-container fluid grid-list-xl>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Codice"
                      v-model="merce.codice"
                      :rules="[$rules.maxlength(20)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-textarea
                      auto-grow
                      label="Descrizione lavorazione"
                      v-model="merce.descrizione"
                      :rules="[$rules.maxlength(1000)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Unità di misura"
                      v-model="merce.unita_misura"
                      :rules="[$rules.maxlength(20)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Quantità"
                      v-model="merce.qta"
                      :rules="[$rules.required, $rules.numeric, $rules.maxlength(7)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Peso unitario (in kg)"
                      v-model="merce.peso"
                      :rules="[$rules.maxlength(9)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Numero colli"
                      v-model="merce.colli"
                      :rules="[$rules.numeric, $rules.maxlength(4)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-btn
                      class="ml-0"
                      color="primary"
                      :disabled="!validFormMerce"
                      @click.native="saveMerce()">
                      Salva
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- bancali -->
    <v-dialog v-model="showBancali" transition="dialog-bottom-transition" :overlay="false" max-width="900" scrollable>
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>
            Bancali disponibili
          </v-toolbar-title>
          <v-spacer />
          <v-btn color="primary" @click="aggiungiBancali()" :disabled="!selectedBancali.length">Aggiungi</v-btn>
          <v-btn icon @click.stop="showBancali = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-flex>
            <v-layout>
              <v-flex xs4>
                <v-autocomplete
                  class="ma-2"
                  label="Opera"
                  :items="opere"
                  item-text="nome"
                  item-value="_id"
                  v-model="ricerca.opera_id"
                  :loading="opereLoading"
                  :search-input.sync="searchOpere"
                  hide-no-data
                  hide-selected
                  clearable />
              </v-flex>
              <v-flex xs4>
                <v-autocomplete
                  class="ma-2"
                  label="Prodotto"
                  :items="prodotti"
                  item-text="nome"
                  item-value="_id"
                  v-model="ricerca.prodotto_id"
                  :loading="prodottiLoading"
                  :search-input.sync="searchProdotti"
                  hide-no-data
                  hide-selected
                  clearable />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  class="ma-2"
                  label="Codice finito"
                  v-model="ricerca.codice_finito" />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 class="pa-2">
                <v-btn
                  class="ml-0"
                  color="primary"
                  @click.native="cerca()">
                  Filtra
                </v-btn>
                <v-btn
                  @click="reset_cerca()">
                  Ripristina
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-form ref="formBancali">
            <v-container fluid>
              <template v-for="ordine in bancaliDisponibili">
                <v-list :key="'ordine-'+ordine.ordini_destinazioni_id" dense>
                  <v-list-tile avatar :key="'tile-'+ordine.ordini_destinazioni_id">
                    <v-list-tile-avatar>
                      <v-checkbox v-model="selectedOrdini" @change="selectOrdine(ordine)" :value="ordine.ordini_destinazioni_id" />
                    </v-list-tile-avatar>
                    <v-list-tile-content v-text="ordine.numero +' - '+ ordine.descrizione +' - '+ ordine.codice_finito + ' - ' + ordine.opera + ' - ' + ordine.prodotto + ' ' + ordine.note" />
                  </v-list-tile>
                </v-list>
                <template v-for="(bancale, index) in ordine.bancali">
                  <v-list :key="'bancale-'+bancale.bancale_id" dense class="ml-4">
                    <v-list-tile avatar :key="'tile-bancale'+bancale.bancale_id">
                      <v-list-tile-avatar>
                        <v-checkbox v-model="selectedBancali" :value="bancale.bancale_id" />
                      </v-list-tile-avatar>
                      <v-list-tile-content v-text="'Bancale ' + (index+1+ordine.bancali_usati) + '/' + ordine.totale_bancali + ' - qtà ' + bancale.qta" />
                    </v-list-tile>
                  </v-list>
                </template>
              </template>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-confirm ref="confirm" />
  </section>
</template>

<script>
import { $EventBus } from '@/eventBus'
import modalConfirm from '@/components/common/ModalConfirm'
import _debounce from 'lodash/debounce'
import { ddtMixin } from '@/components/produzione/ddt/ddtMixin'

export default {
  mixins: [ddtMixin],
  props: {
    ddt: Object
  },
  data: () => ({
    merci: [],
    merce: {},
    ricerca: {},
    showMerce: false,
    showBancali: false,
    validFormMerce: true,
    validFormBancali: true,
    bancaliDisponibili: [],
    bancaliDdt: [],
    selectedOrdini: [],
    selectedBancali: [],
    searchOpere: null,
    searchProdotti: null,
    opereLoading: false,
    prodottiLoading: false,
    opere: [],
    prodotti: [],
    peso: 0,
    colli: 0,
    fab: false,
    hover: false
  }),
  components: {
    'modal-confirm': modalConfirm
  },
  methods: {
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    },
    getMerci () {
      this.$plsqlMethod('ddtp', 'get_merci_ddt', { id: this.ddt._id })
        .then(response => { this.merci = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    getBancali () {
      this.$plsqlMethod('ddtp', 'get_bancali_ddt', { id: this.ddt._id })
        .then(response => { this.bancaliDdt = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
      this.$plsqlMethod('ddtp', 'get_peso', { id: this.ddt._id })
        .then(response => {
          this.peso = response.data.peso
          this.colli = response.data.colli
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    openMerceLibera (m) {
      if (m) {
        this.merce = m
      } else {
        this.merce = {}
        this.$refs.formMerceLibera.reset()
      }
      this.showMerce = true
    },
    openBancali () {
      if (this.ddt.destinazione_id) {
        this.ricerca.id = this.ddt.destinazione_id
        this.$plsqlMethod('ddtp', 'get_bancali_disponibili', this.ricerca)
          .then(response => {
            this.bancaliDisponibili = response.data
            this.showBancali = true
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      }
    },
    saveMerce () {
      let t = this
      this.merce.ddt_id = this.ddt._id
      if (this.$refs.formMerceLibera.validate()) {
        if (!this.merce.merce_id) {
          this.$plsqlMethod('ddtp', 'aggiungi_merci', this.merce)
            .then(response => {
              this.showMerce = false
              t.getMerci()
              t.getBancali()
            })
        } else {
          this.$plsqlMethod('ddtp', 'modifica_merci', this.merce)
            .then(response => {
              this.showMerce = false
              t.getMerci()
              t.getBancali()
            })
        }
      }
    },
    selectOrdine (o) {
      let _this = this
      o.bancali.forEach(function (b) {
        let idx = _this.selectedBancali.indexOf(b.bancale_id)
        if (_this.selectedOrdini.indexOf(o.ordini_destinazioni_id) === -1) {
          if (idx > -1) _this.selectedBancali.splice(idx, 1)
        } else {
          if (idx === -1) _this.selectedBancali.push(b.bancale_id)
        }
      })
    },
    aggiungiBancali () {
      let t = this
      this.$plsqlMethod('ddtp', 'aggiungi_bancali', { id: this.ddt._id, bancali: this.selectedBancali })
        .then(response => {
          this.showBancali = false
          t.getBancali()
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    eliminaBancali (b) {
      let ddtId = this.ddt._id
      let t = this
      this.$refs.confirm.show({
        modalTitle: 'Conferma eliminazione',
        modalText: 'Sei sicuro di voler eliminare i bancali <strong>' + b.codice_finito + ' - ' + b.opera + '</strong>?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: function (banc) {
          this.$plsqlMethod('ddtp', 'rimuovi_bancali', { id: ddtId, bancali: banc.bancali })
            .then(response => {
              t.$refs.confirm.hide()
              t.getBancali()
            })
            .catch(err => {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            })
        },
        modalObj: b
      })
    },
    cerca () {
      this.openBancali()
    },
    reset_cerca () {
      if (!this.isEmpty(this.ricerca)) {
        this.ricerca = {}
        this.cerca()
      }
    },
    eliminaMerce (m) {
      let t = this
      this.$refs.confirm.show({
        modalTitle: 'Conferma eliminazione',
        modalText: 'Sei sicuro di voler eliminare la merce <strong>' + m.codice + ' - ' + m.descrizione + '</strong>?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: function (merc) {
          this.$plsqlMethod('ddtp', 'rimuovi_merci', { merce_id: merc.merce_id })
            .then(response => {
              t.$refs.confirm.hide()
              t.getMerci()
            })
            .catch(err => {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            })
        },
        modalObj: m
      })
    },
    cercaOpere (val) {
      if (!val) return

      this.opereLoading = true

      this.$plsqlMethod('opera', 'search', { text: val, cliente: this.ddt.cliente_id, attivo: 1 })
        .then(response => {
          this.opere = response.data
          this.opereLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Opere' })
          this.opereLoading = false
        })
    },
    cercaProdotti (val) {
      if (!val) return

      this.prodottiLoading = true

      this.$plsqlMethod('opera', 'searchp', { text: val, cliente: this.ddt.cliente_id, attivo: 1 })
        .then(response => {
          this.prodotti = response.data
          this.prodottiLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Prodotti' })
          this.prodottiLoading = false
        })
    },
    stampa () {
      let processDdtForMail = this.processDdtForMail
      this.$plsqlMethod('ddtp', 'get_ddt_for_mail', { ddts: [this.ddt._id] })
        .then((response) => {
          response.data.forEach(function (ddt) {
            ddt = processDdtForMail(ddt)
          })

          this.$http({
            method: 'post',
            url: '/mail/ddt/pdf',
            responseType: 'blob',
            data: { ddts: response.data }
          }).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            this.$openFileNewTab(blob) // con adblocker picche ocio
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf del ddt' })
          })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf del ddt' })
        })
    }
  },
  watch: {
    searchOpere (val) {
      this.debouncedcercaOpere(val)
    },
    searchProdotti (val) {
      this.debouncedcercaProdotti(val)
    }
  },
  created: function () {
    this.debouncedcercaOpere = _debounce(this.cercaOpere, 500)
    this.debouncedcercaProdotti = _debounce(this.cercaProdotti, 500)
  },
  mounted () {
    this.getMerci()
    this.getBancali()
  }
}
</script>

<style>
.fix-speed-dial.v-speed-dial--direction-top .v-speed-dial__list{
  left: -1em!important;
  bottom: 150%!important;
}
</style>
